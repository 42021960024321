import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { Event } from "@root/utils/entities";
import moment from "moment";
import { useMemo } from "react";
import { EventCategories } from "./common/EventCategories";
import { StyledChip } from "./common/styled";

interface EventItemProps {
  event: Event;
  onView: () => void;
}
export const EventListItem = ({ event, onView }: EventItemProps) => {
  const start = useMemo(
    () => moment.utc(event.dateRange?.start).local(),
    [event.dateRange?.start]
  );
  const end = useMemo(
    () => moment.utc(event.dateRange?.end).local(),
    [event.dateRange?.end]
  );

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{
          padding: "0 !important",
          border: "1px solid lightgray",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            gap: "1rem",
            justifyContent: "space-between",
            height: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.8rem",
              alignItems: "start"
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                flex: "1 1 60%"
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontStyle: "bold",
                  fontWeight: 700,
                  fontSize: "1rem",
                  lineHeight: "1rem",
                }}
              >
                {event.name}
              </Typography>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  fontWeight: 500,
                  fontSize: "0.80rem",
                  lineHeight: "0.80rem",
                  color: theme.palette.info.main,
                })}
              >
                {`Start: ${start.format("MMMM Do, YYYY")} @ ${start.format("h:mm a")}`}
              </Typography>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  fontWeight: 500,
                  fontSize: "0.80rem",
                  lineHeight: "0.80rem",
                  color: theme.palette.info.main,
                })}
              >
                {`End: ${end.format("MMMM Do, YYYY")} @ ${end.format("h:mm a")}`}
              </Typography>
              <Stack direction="column" sx={{ gap: "0.5rem" }}>
                {event.locationName && (
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "1rem",
                      fontSize: "0.8rem",
                      color: "#70757a",
                    }}
                  >
                    {event.locationName}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 400,
                    lineHeight: "1rem",
                    fontSize: "0.8rem",
                    color: "#70757a",
                  }}
                >
                  {event.location?.address?.textDisplay}
                </Typography>
              </Stack>
            </Box>
            {event.bannerImageUrl && (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flex: 1,
                }}
              >
                <img
                  src={event.bannerImageUrl}
                  style={{
                    width: "6rem",
                    objectFit: "contain",
                    objectPosition: "center",
                    height: "intrinsic"
                  }}
                />
              </Box>
            )}
          </Box>
          <EventCategories event={event} />
          <StyledChip
            variant="outlined"
            label="View Event"
            sx={(theme) => ({
              width: "100%",
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            })}
            onClick={onView}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
